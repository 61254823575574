export default {
  /**
     * 属性拷贝
     * @param source 源对象
     * @param target 目标对象
     * @param map 剪纸隐射关系
     */
  mapKeys (source, target, map) {
    Object.keys(map).forEach(key => {
      if (source[key]) {
        target[map[key]] = source[key]
      }
    })
  }
}
