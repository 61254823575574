<template>
  <div class="service-edit">
    <el-card class="box-card">
      <div slot="header">
        <div class="title fz-18">填写服务信息</div>
      </div>
      <el-form class="serviceForm" :rules="formDataRules" :model="formData" ref="serviceForm"
               label-width="120px">
        <el-form-item prop="serviceType" required label="服务类目">
          <el-cascader
            v-model="formData.serviceType"
            @change="onServiceCategoryChange"
            style="width: 400px;" :options="serviceCategory"
          ></el-cascader>
        </el-form-item>
        <template v-if="attributeOptions">
          <el-form-item label="服务属性" class="serviceAttribute" prop="attribute">
            <template v-for="attribute in attributeOptions">
              <div :key="attribute.name" class="sctp-flex attribute-group">
                <span style="width: 100px;text-align: right;">{{ attribute.name }}：</span>
                <el-checkbox-group class="sctp-flex-item-1" v-model="formData.attribute">
                  <template v-for="item in attribute.children">
                    <el-checkbox :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
                  </template>
                </el-checkbox-group>
              </div>
            </template>
          </el-form-item>
        </template>
        <el-form-item class="small" label="服务名称"  required prop="servicename">
          <el-input v-model="formData.servicename" maxlength="40">
            <span slot="append">还可输入<span class="sctp-red">{{
                40 - (formData.servicename && formData.servicename.length || 0)
              }}</span>字</span>
          </el-input>
        </el-form-item>
        <el-form-item class="small" label="关键字" required prop="keyword">
          <el-input v-model="formData.keyword" maxlength="30">
            <span slot="append">还可输入<span
              class="sctp-red">{{
                30 - (formData.keyword && formData.keyword.length || 0)
              }}</span>字</span>
          </el-input>
          <div class="sctp-lh-normal">提示：可用多个简单明了的关键词来描述服务，多个关键词之间请用","隔开</div>
        </el-form-item>
        <el-form-item class="inline-form" label="服务价格" prop="serviceprice">
          <el-input type="number" v-model="formData.serviceprice"></el-input>
          <span>元起&emsp;&emsp;</span>
          <span>&emsp;&emsp;单位:</span>
          <el-select v-model="formData.unit"  placeholder="">
            <el-option
              v-for="item in unitOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <div class="sctp-lh-normal">提示：请填写合理的价格，限制范围1元~1000万元</div>
        </el-form-item>
        <el-form-item style="width: 50%;" class="small" label="服务数量" prop="serviceamount">
          <el-input :maxlength="5" min="1" max="10000"
                    v-model.number="formData.serviceamount">
            <div slot="append">最多10000</div>
          </el-input>
        </el-form-item>
        <el-form-item label="服务封面" :required="true" prop="servicecover">
          <el-upload
            :http-request="fileUploadFunction"
            action=""
            :limit="5"
            :data="{...uploadConfig.uploadEnum.SERVICE}"
            :on-exceed="uploadConfig.fileExceed"
            :file-list="formData.servicecover"
            ref="uploadFile"
            list-type="picture-card"
            :multiple="true"
            :class="[{hiddenUpload: formData.servicecover && formData.servicecover.length >= 5}]"
            :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'servicecover'})"
            :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'servicecover'})"
            :on-remove="uploadConfig.onRemove.bind(null, {key: 'servicecover'})">
            <i class="el-icon-plus"></i>
            <div class="el-upload__tip" slot="tip">
              <div>提示：</div>
              <div>1.只能上传jpg/png文件</div>
              <div>2.可上传1~5张图片</div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item class="auto" label="服务详情">
          <ckeditor v-model="formData.servicedetails"></ckeditor>
        </el-form-item>
        <el-form-item label="上传附件">
          <el-upload
            :http-request="fileUploadFunction"
            action=""
            :limit="2"
            :data="{...uploadConfig.uploadEnum.SERVICE}"
            :on-exceed="uploadConfig.fileExceed"
            :file-list="formData.serviceannex"
            ref="uploadFileServiceannex"
            :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'servicecover'})"
            :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'serviceannex'})"
            :on-remove="uploadConfig.onRemove.bind(null, {key: 'serviceannex'})">
            <el-button size="small" icon="el-icon-link">上传附件</el-button>
            <div class="el-upload__tip" slot="tip">
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            @click="onSubmitClick"
          >
            保存
          </el-button>
          <el-button size="small" @click="onTemporaryStorageClick" type="primary">暂存
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>

  </div>
</template>

<script>
import asyncValidator from '../../../resources/js/async-validator'
import objectUtil from '../../../resources/js/objectutil'
import uploadEnum from "@/resources/js/uploadenum";

const config = require('../../../resources/js/config');
export default {
  name: 'serviceEdit',
  props: ['id'],
  data() {
    return {
      serviceId:null,
      formData: {
        serviceType: [],
        servicename: null,
        levelonetype: null,
        leveltwotype: null,
        levelthreetype: null,
        attribute: [],
        keyword: null,
        serviceprice: null,
        serviceamount: null,
        servicedetails: null,
        serviceannex: [],
        servicecover: [],
        unit: null
      },
      formDataRules: {
        serviceType: [
          {required: true, message: '请选择类目', trigger: ['change', 'blur']},
        ],
        attribute: [
          {required: true, message: '请选择属性', trigger: 'change'}
        ],
        servicename: [
          {max: 40, message: '最多40个字符', trigger: 'change'},
          {

            validator: (rule, value, callback) => {
              if (!value){
                callback(new Error('请输入服务名称'))
              } else if (!/^\S+$/.test(value)) {
                callback(new Error('服务名称格式有误'))
              } else {
                callback()
              }
            }
          }
        ],
        keyword: [
          {max: 30, message: '最多30个字符', trigger: 'change'},
          {
            validator: (rule, value, callback) => {
              const reg = /^([\w\d\u4e00-\u9fa5]+,?){1,5}$/;
              if (!value) {
                callback()
              } else {
                value = value + ',';
                if (reg.test(value)) {
                  callback()
                } else {
                  callback(new Error('关键字不符合规则'))
                }
              }
            }
          }
        ],
        serviceprice: [
          {required: true, message: '请输入价格', trigger: 'blur'},
          {
            validator: (rule, value, callback) => {
              if (value < 1 || value > 10000000) {
                callback(new Error('价格范围只能1元~1000万元之间'))
              } else {
                callback()
              }
            }
          }
        ],
        serviceamount: [
          {required: true, message: '请输入服务数量', trigger: ['blur', 'change']},
          {validator: asyncValidator.validNum, message: '只能输入数字', trigger: ['blur', 'change']},
          {
            validator: (rule, value, callback) => {
              if (value < 1 || value > 10000) {
                callback(new Error(rule.message))
                return;
              }
              callback();
            }, message: '数量限制范围1~10000', trigger: ['blur', 'change']
          },
        ],
        servicecover: [
          {required: true, message: '请上传封面'}
          // {
          //   validator: (rule, value, callback) => {
          //     let {servicecover} = this.formData;
          //     if (!servicecover || servicecover.length <= 0) {
          //       callback(new Error('请上传封面'));
          //     } else {
          //       callback();
          //     }
          //   }
          // }
        ]
      },
      attributeOptions: null,
      typeOptions: [],
      unitOptions: [
        {label: '个', value: '个'},
        {label: '次', value: '次'},
        {label: '页', value: '页'},
        {label: '套', value: '套'},
        {label: '分钟', value: '分钟'},
        {label: '秒', value: '秒'},
        {label: '月/人', value: '月/人  '}
      ],
      uploadConfig: {
        uploadEnum,
        uploadSuccess: ({key}, response, file, fileList) => {
          if (response.code) {
            this.formData[key] = fileList;
            this.$refs.serviceForm.validateField(key);
            this.$message.success('文件上传成功！');
          }else {
            let uid = file.uid
            if (key == 'servicecover'){
              let idx = this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === uid)
              this.$refs.uploadFile.uploadFiles.splice(idx, 1)
            }else if (key == 'serviceannex'){
              let idx = this.$refs.uploadFileServiceannex.uploadFiles.findIndex(item => item.uid === uid)
              this.$refs.uploadFileServiceannex.uploadFiles.splice(idx, 1)
            }


            this.$message.error('上传文件失败')
          }
        },
        onRemove: ({key}, file, fileList) => {
          this.formData[key] = fileList
        },
        beforeUpload: ({key}, file) => {
          let result = false;
          const uploadType = ['jpg','png'];
          const filetype=file.name.replace(/.+\./,'');
          const isImage = uploadType.indexOf(filetype.toLowerCase())!= -1;
          const isLtSize = file.size / 1024 / 1024 < 1
          if (!isImage) {
            this.$message.error('上传文件只能是 图片 格式!')
          }
          if (!isLtSize) {
            this.$message.error('上传文件大小不能超过 512K!')
          }
          result = isImage && isLtSize;
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      },
    }
  },
  components: {
    ckeditor: () => import('@CMP/ckeditor/Index'),
  },
  methods: {
    onSubmitClick() {
      this.$refs.serviceForm.validate(valid => {
        if (!this.formData.unit){
          this.$message.warning('请输入价格单位');
          return
        }
        if (valid) {
          this.doSubmitForm()
        } else {
          this.$message.warning('请检查表单');
          return false
        }
      })
    },
    onTemporaryStorageClick() {
      const formData = this.formData;
      console.log('zzasdasd')
      console.log(formData)
      let checkField = [];
      let exclude = ['typeValue'];
      Object.keys(formData).forEach(key => {
        if (exclude.includes(1)) {
          this.$alert('baga!')
          return;
        }
        let value = formData[key];
        let needCheck = false;
      })
      this.doSubmitForm(true);
      // if (checkField.length === 0) {
      //   this.submitFormData(true);
      // } else {
      //   let hasError;
      //   let counter = 0;
      //   this.submitFormData(true);
      // this.$refs['goodsForm'].validateField(checkField, (errorMessage => {
      //   ++counter;
      //   if (errorMessage) {
      //     if (!hasError) {
      //       hasError = true;
      //       this.$message.warning(errorMessage);
      //     }
      //   } else if (!hasError && counter === checkField.length) {
      //     this.submitFormData(true);
      //   }
      // }));
      // }
    },
    doSubmitForm(temp = false) {
      const formData = JSON.parse(JSON.stringify(this.formData));
      console.log(1)
      console.log(formData)
      const parsePath = (obj) => {
        return obj.map(item => {
          let path = '';
          if (item.response) {
            path = item.response.src
          } else if (item.url) {
            path = item.url.substring(item.url.indexOf('/upload/'))
          }
          return path
        }).join(',')
      };
      if (formData.serviceannex) {
        formData.serviceannex = parsePath(formData.serviceannex)
      }
      if (formData.servicecover) {
        formData.servicecover = parsePath(formData.servicecover)
      }
      const params = {};
      objectUtil.mapKeys(formData, params, {
        servicename: 'servicename',
        levelonetype: 'levelonetype',
        leveltwotype: 'leveltwotype',
        levelthreetype: 'levelthreetype',
        attribute: 'attribute',
        keyword: 'keyword',
        serviceprice: 'serviceprice',
        serviceamount: 'serviceamount',
        servicedetails: 'servicedetails',
        serviceannex: 'serviceannex',
        servicecover: 'servicecover',
        unit: 'unit'
      });
      params.temporary_storage = temp
      this.$request.post({
        reqcode: '1090',
        reqdesc: '发布服务',
        ...params,
        userId: this.user.userId,
        serviceid: this.serviceId
      }).then(res => {
        this.$message.success('提交成功');
        this.goPage('/shop/sale/service')
      })
    },
    getOptions() {
      this.$request.post({
        reqcode: '1088',
        reqdesc: '服务分类'
      }).then(res => {
        const {types} = res.retdata;
        this.typeOptions = types
      })
    },
    getAttribute(typeId) {
      if (!typeId) {
        this.attributeOptions = null;
        return;
      }
      this.$request.post({
        reqcode: '1089',
        reqdesc: '获取属性',
        typeId: typeId
      }).then(res => {
        const {attribute} = res.retdata;
        this.attributeOptions = attribute
      })
    },
    getServiceDetail() {

      // if (!this.id) {
      //   return
      // }
      if (!this.serviceId) {
          return
        }

      this.$request.post({
        reqcode: '1234',
        reqdesc: '服务编辑详情',
        userId: this.user.userId,
        serviceId: this.serviceId
      }).then(res => {
        const {retdata} = res;
        const castToFileList = (str) => {
          try {
            return str.map(item => {
              return {
                url: item,
                name: item.substring(item.lastIndexOf('/') + 1)
              }
            })
          } catch (e) {
            return []
          }
        };
        this.formData = {
          servicename: retdata.servicename,
          levelonetype: retdata.levelonetype,
          leveltwotype: retdata.leveltwotype,
          levelthreetype: retdata.levelthreetype,
          serviceType: [retdata.levelonetype, retdata.leveltwotype],
          attribute: retdata.attribute,
          keyword: retdata.keyword,
          serviceprice: retdata.serviceprice,
          serviceamount: retdata.serviceamount,
          servicedetails: retdata.servicedetails,
          serviceannex: castToFileList(retdata.serviceannexs),
          servicecover: castToFileList(retdata.servicecovers),
          unit: retdata.unit
        };
      })
    },
    onServiceCategoryChange(value) {
      this.formData.levelonetype = value[0];
      this.formData.leveltwotype = value[1];
      this.formData.levelthreetype = value[2];
    }
  },
  computed: {
    serviceCategory() {
      let options = [];
      let {typeOptions} = this;
      if (typeOptions) {
        options = typeOptions.map(item => {
          item.value = item.id;
          item.label = item.name;
          if (item.children && item.children.length > 0) {
            item.children.map(item => {
              item.value = item.id;
              item.label = item.name;
              delete item.children;
              // if (item.children && item.children.length > 0) {
              //   item.children.map(item => {
              //     item.value = item.id;
              //     item.label = item.name;
              //     delete item.children;
              //     return item;
              //   })
              // }
              return item;
            })
          }
          return item;
        })
      }
      return options;
    },
  },
  watch: {
    'formData.leveltwotype': {
      handler(newVal, oldVal) {
        this.getAttribute(newVal)
      }
    }
  },
  beforeMount() {
    const {serviceId} = this.getQuery();
    this.serviceId = serviceId
    this.getOptions();
    this.getServiceDetail()
  }
}
</script>

<style scoped lang="scss">
.service-edit {
  ::v-deep {
    .el-card__header .title {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        display: inline-block;
        height: 100%;
        width: 3px;
        background: $main-color;
        top: 0;
        bottom: 0;
        left: -10px;
      }
    }

    .serviceForm {
      .el-form-item:not(.auto) {
        .el-form-item__content {
          width: 60%;
        }
      }
    }
  }
}

.inline-form ::v-deep .el-input {
  width: 180px;
}

.serviceAttribute .el-checkbox {
  margin-right: 10px;
}

.attribute-group + .attribute-group {
  border-top: 1px solid #eee;
}

.attribute-group {
  background-color: #f8f8f8;
  line-height: 2.2;
  padding: 10px 0;
}
</style>
